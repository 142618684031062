import {NgModule} from '@angular/core';

import {SysTranslatePipe} from './translation.pipe';
import {CommonModule} from "@angular/common";
import {DefaultValuePipe} from "./default-value.pipe";
import {LocalDatePipe} from "./local-date.pipe";

@NgModule({
    declarations: [SysTranslatePipe, DefaultValuePipe, LocalDatePipe],
    imports: [CommonModule],
    exports: [SysTranslatePipe, DefaultValuePipe, LocalDatePipe],
})
export class PipeModule {

}
