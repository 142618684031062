import {Pipe, PipeTransform} from '@angular/core';
import {Observable} from "rxjs/Observable";

@Pipe({
    name: 'defaultValue'
})

export class DefaultValuePipe implements PipeTransform {
    transform(value: any, ...args: any[]): any {
        if (value == null || value == undefined || typeof(value) !== "string") {
            return args[0];
        }
        return value;
    }
}
