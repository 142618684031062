import {Injectable} from "@angular/core";
import {Observable} from "rxjs/Observable";
import {DocumentInfo} from "./model/index";
import {FileType} from "./model/index";

@Injectable()
export abstract class DocumentService {

    /**
     * Uploads file without authorization.
     * @param {string} categoryId Document category id
     * @param {string} title Document title
     * @param {File} file File to be uploaded
     * @param {Map} metaData Document meta-data
     * @return {Observable<DocumentInfo>} Document info of stored temp document
     */
    public abstract upload(categoryId: string, title: string, file: File, metaData?: Map<string, any>): Observable<DocumentInfo>;


    /**
     * Uploads file with authorization.
     * @param {string} categoryId Document category id
     * @param {string} title Document title
     * @param {File} file File to be uploaded
     * @param {string} authInfo Auth token for upload from corresponding provider (i.e. DeService, ClassifierService, UmService, MeService)
     * @param {Map} metaData Document meta-data
     * @return {Observable<DocumentInfo>} Document info of stored temp document
     */
    public abstract uploadWithAuth(categoryId: string, title: string, file: File, authInfo: string, metaData?: Map<string, any>): Observable<DocumentInfo>;


    /**
     * Uploads file with authorization, and reports progress of uploading through emitted HttpEventTypes.
     * @param {string} categoryId Document category id
     * @param {string} title Document title
     * @param {File} file File to be uploaded
     * @param {string} authInfo Auth token for upload from corresponding provider (i.e. DeService, ClassifierService, UmService, MeService)
     * @param {Map} metaData Document meta-data
     * @return {Observable<DocumentInfo>} HttpEventType, with Response's body containing document info of the stored temp document
     */
    public abstract uploadAuthWithProgress(categoryId: string, title: string, file: File, authInfo: string, metaData?: Map<string, any>): Observable<any>;


    /**
     * Validates the file by user policy for future safe upload.
     * @param {File} file File to be uploaded
     * @return {Observable<any>} void value emitted on success
     */
    public abstract validate(file: File): Observable<any>;


    /**
     * Confirms the temp document for given entityId.
     * @param {number} documentId Document id
     * @param {number} entityId Document's entity id of
     * @return {DocumentInfo} Document info of confirmed document
     */
    public abstract confirmUpload(documentId: number, entityId: number): Observable<DocumentInfo>;


    /**
     * Gets temporary download URL for the given document. Can be used as tempUrl for downloadDocument.
     * @param {number} documentId Document id
     * @param {string} authInfo Auth token for download from corresponding provider (i.e. DeService, ClassifierService, UmService, MeService)
     * @return {string} Download URL
     */
    public abstract getDownloadUrl(documentId: number, authInfo: string): Observable<string>;


    /**
     * Gets temporary download URLs for the given documents. Can be used as tempUrl for downloadDocument.
     * @param {Array<number>} documentIds Document ids
     * @param {string} authInfo Auth token for download from corresponding provider (i.e. DeService, ClassifierService, UmService, MeService)
     * @return {Map<number, string>} Map of document ids and download URLs
     */
    public abstract getDownloadUrls(documentIds: Array<number>, authInfo: string): Observable<Map<number, string>>;


    /**
     * Downloads the confirmed document in browser by tempUrl.
     * @param {string} tempUrl URL provided by getDownloadUrl(s)
     * @param {FileType} fileType File type
     */
    public abstract downloadDocument(tempUrl: string, fileType: FileType): void;


    /**
     * Downloads the temp document in browser by id.
     * @param {number} id Document id
     */
    public abstract downloadTempDocument(id: number): void;


    /**
     * Gets confirmed document URL (e.g. for image src).
     * @param {number} documentId Document id
     * @param {string} authInfo Auth token for download from corresponding provider (i.e. DeService, ClassifierService, UmService, MeService)
     * @param {FileType} fileType File type
     * @return {string} Document URL
     */
    public abstract getDocumentUrl(documentId: number, authInfo: string, fileType: FileType): Observable<string>;


    /**
     * Gets temp document URL (e.g. for image src).
     * @param {number} documentId Document id
     * @param {FileType} fileType File type
     * @return {string} Document URL
     */
    public abstract getTempDocumentUrl(documentId: number, fileType?:FileType): Observable<string>;


    /**
     * Gets confirmed document info by id.
     * @param {number} documentId Document id
     * @return {DocumentInfo} Document info
     */
    public abstract getDocumentInfo(documentId: number): Observable<DocumentInfo>;


    /**
     * Gets temp document info by id.
     * @param {number} documentId Document id
     * @return {DocumentInfo} Document info
     */
    public abstract getTempDocumentInfo(documentId: number): Observable<DocumentInfo>;


    /**
     * Gets document infos for the corresponding entity.
     * @param {string} categoryId Category id
     * @param {number} entityId Entity id
     * @return {Array<DocumentInfo>} Array of entity's documents' infos
     */
    public abstract getDocumentsByEntityId(categoryId: string, entityId: number): Observable<DocumentInfo[]>;


    /**
     * Deletes document by id.
     * @param {number} documentId Document id
     * @return {boolean} Boolean value for delete's success
     */
    public abstract deleteDocumentById(documentId: number): Observable<boolean>;


    /**
     * Verifies whether the document by given id is confirmed.
     * @param {number} documentId Document id
     * @return {boolean} Boolean value for (not) confirmed
     */
    public abstract getIsDocumentPresent(documentId: number): Observable<boolean>;

}
