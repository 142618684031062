import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {ControlMetadata} from "@synisys/idm-dynamic-controls-metadata";
import {Entity} from '@synisys/idm-de-core-frontend';
import {PolicyOptionDto} from "@synisys/idm-policy-client-js";

@Component({
    moduleId: module.id,
    selector: "sis-document",
    templateUrl: "sis-document.component.html"
})
@ControlMetadata({
    groups: [
        {
            identity: "Controls",
            name: "Controls",
        },
    ],
    template: `
        <sis-document
                    [isReadonly]="%{isReadonly}"
                    [documentId]="%{field}"
                    [entity]="entity"
                    [category]="category"
                    [documentRootName]="%{documentRootName}? %{documentRootName}: '%{field-name}'"
                    [filePolicy]="%{filePolicy}"
                    [id]="%{id}"
                    [height]="%{height}"
                    [documentPreviewEnabled]="%{documentPreviewEnabled}"
                    [showUploadDate]="%{showUploadDate}"
                    [showDocumentSize]="%{showDocumentSize}"
                    [showUploadedBy]="%{showUploadedBy}"
                    (onDocumentUpload)="%{onDocumentUpload}"
                    (onDocumentRemove)="%{onDocumentRemove}">
        </sis-document>`,
    iconInfo: "fa-caret-square-o-down",
    name: "DocumentComponent",
    defaultActions: {
        'onDocumentUpload' : 'submitDocument',
        'onDocumentRemove' : 'removeDocument'
    }
})
export class DocumentComponent implements OnInit {

    private _entityId: number;

    @Input() public isReadonly: boolean;
    @Input() public documentId: number;
    @Input('entity') set entity(entity: Entity) {
        this._entityId = entity ? (entity.getInstanceId() != undefined ? entity.getInstanceId() : entity.getId()) : null;
        this._entityId = this._entityId || -1;
    }
    @Input() public category: string;
    @Input() public documentRootName: string;
    @Input() public filePolicy: PolicyOptionDto;
    @Input() public id: string;
    @Input() public height: number;

    @Input() public documentPreviewEnabled: boolean = true;
    @Input() public showUploadDate: boolean = true;
    @Input() public showDocumentSize: boolean = true;
    @Input() public showUploadedBy: boolean = true;

    @Output() onDocumentUpload = new EventEmitter<number>();
    @Output() onDocumentRemove = new EventEmitter<number>();
    @Output() onMetaDataUpdate = new EventEmitter<Map<string, any>>();

    @Input() public metaData: Map<string, any> = null;

    public ngOnInit(): void {
    }

    constructor() {
    }

    public onUpload(value: number) {
        this.onDocumentUpload.emit(value);
    }

    public onRemove(value: number) {
        this.onDocumentRemove.emit(value);
    }

    get entityId(): number {
        return this._entityId;
    }

    get previewEnabled(): boolean {
        return String(this.documentPreviewEnabled) === '' ? true : this.documentPreviewEnabled;
    }

    get showDate(): boolean {
        return String(this.showUploadDate) === '' ? true : this.showUploadDate;
    }

    get showSize(): boolean {
        return String(this.showDocumentSize) === '' ? true : this.showDocumentSize;
    }

    get showUser(): boolean {
        return String(this.showUploadedBy) === '' ? true : this.showUploadedBy;
    }

    public metaDataUpdated (value: Map<string, any>) {
        this.onMetaDataUpdate.emit(value);
    }

}
