export class DocumentInfo {
    public id: number;
    public userId: number;
    public name: string;
    public categoryId?: string;
    public entityId?: number;
    public metaData?: object;
    public created: number;
    public title: string;
    public size: string;
}
