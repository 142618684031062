import { Component, Input, Output, EventEmitter, AfterViewInit } from "@angular/core";
import { ControlMetadata } from "@synisys/idm-dynamic-controls-metadata";
import { DocumentService } from "../../document.service";
import { Observable } from "rxjs/Observable";
import { HttpErrorResponse } from "@angular/common/http";
import { MessageService } from "@synisys/idm-message-language-service-client-js";

@Component({
    moduleId: module.id,
    selector: "sis-upload",
    styleUrls: ["sis-upload.component.css"],
    templateUrl: "sis-upload.component.html",
})
@ControlMetadata({
    groups: [
        {
            identity: "Controls",
            name: "Controls",
        },
    ],
    template: `<sis-upload class="sis-upload"   [id]="'%{id}'" [maxSize]="'%{maxSize}'"
                                                        (fileUploadAction)="%{fileUploadAction}" >
                       </sis-upload>`,
    iconInfo: "fa-caret-square-o-down",
    name: "Upload",
})
export class Upload implements AfterViewInit {
    private static MB_IN_BYTES: number = 1048576;
    private static FILE_VALIDATION_DEFAULT_MESSAGE: string = "Please select the file.";
    private static SIZE_VALIDATION_DEFAULT_MESSAGE: string = "File is too large.";
    private static INPUT_ID: string = "-file";
    @Input()
    public maxSize: number;
    @Input()
    public id: string;

    @Input()
    public set fileValidationMessage(fileValidationMessage: string) {
        this._fileValidationMessage = fileValidationMessage;
        this._validationMessage = this._validationMessage ? this._fileValidationMessage : "";
    }

    public get fileValidationMessage(): string {
        return this._fileValidationMessage;
    }

    @Input()
    public sizeValidationMessage: string;
    /** Allow you to add handler after its completion. Bubble up response text from remote. */
    @Output()
    public fileUploadAction = new EventEmitter();
    private _uploadedFile: File;
    private _fileValidationMessage: string;
    private _validationMessage: string = "";
    private _fileUpload: HTMLInputElement;

    get uploadedFile(): File {
        return this._uploadedFile;
    }

    set uploadedFile(value: File) {
        this._uploadedFile = value;
    }

    get validationMessage(): string {
        return this._validationMessage;
    }

    @Input()
    set validationMessage(value: string) {
        this._validationMessage = value;
    }

    constructor(private documentService: DocumentService, private messageService: MessageService) {}

    ngAfterViewInit(): void {
        this._fileUpload = document.getElementById(`${this.id}${Upload.INPUT_ID}`) as HTMLInputElement;
        this._fileUpload.onchange = () => {
            this._uploadedFile = this._fileUpload.files[0];
        };
    }

    onClick() {
        this._fileUpload.click();
        this._validationMessage = "";
    }

    public uploadFile() {
        this.validate().subscribe(
            next => {
                this.fileUploadAction.emit({
                    file: this._uploadedFile,
                });
            },
            (error: any) => {
                if (error instanceof HttpErrorResponse) {
                    if (error.status == 400) {
                        if (error.error.hasOwnProperty("type") && error.error.type === "EXTENSION") {
                            this.messageService
                                .getMessageWithPlaceholder("document.service.errors.extension", [
                                    error.error.currentExtension,
                                ])
                                .subscribe(message => (this.validationMessage = message));
                        } else if (error.error.hasOwnProperty("type") && error.error.type === "SIZE") {
                            this.messageService
                                .getMessageWithPlaceholder("document.service.errors.size", [error.error.currentSize])
                                .subscribe(message => (this.validationMessage = message));
                        }
                    } else {
                        this.validationMessage = error.message;
                    }
                } else {
                    this.validationMessage = error;
                }
            }
        );
    }

    private validate(): Observable<any> {
        if (!this._uploadedFile) {
            return Observable.throw(Upload.FILE_VALIDATION_DEFAULT_MESSAGE);
        }

        if (this.maxSize && this._uploadedFile.size > this.maxSize * Upload.MB_IN_BYTES) {
            return Observable.throw(Upload.SIZE_VALIDATION_DEFAULT_MESSAGE);
        }

        return this.documentService.validate(this._uploadedFile);
    }

    public cancelFile() {
        this._uploadedFile = null;
        this._fileUpload.value = "";
    }
}
