import {Pipe, PipeTransform} from '@angular/core';
import {Observable} from "rxjs/Observable";
import {MessageService} from "@synisys/idm-message-language-service-client-js";

@Pipe({
    name: 'sysTranslate'
})

export class SysTranslatePipe implements PipeTransform {
      public constructor(private messageService: MessageService) {
    }

    public transform(key: string): Observable<string> {
        key = key ? key.trim() : '';

        if (key.length === 0) {
            return Observable.from(['']);
        }

        return this.messageService.getMessage(key);
    }
}
