import {CommonModule}          from '@angular/common';
import {NgModule}              from '@angular/core';
import {FormsModule}           from '@angular/forms';
import {CookieService}         from '@synisys/idm-authentication-client-js';
import {ControlsModule}        from '@synisys/idm-ng-controls';
import {WebcamModule}          from 'ngx-webcam';
import {CapturePopup}          from './controls/capture/sis-photo-capture-popup.component';
import {PhotoCaptureComponent} from './controls/capture/sis-photo-capture.component';
import {DocumentComponent}     from './controls/document/sis-document.component';
import {Download}              from './controls/download/sis-download.component';
import {NewUpload}             from './controls/new-upload/sis-upload-2.component';
import {Upload}                from './controls/upload/sis-upload.component';
import {MaterialModule}        from './material.module';
import {PipeModule}            from './pipes/pipe.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MaterialModule,
        ControlsModule,
        PipeModule,
        WebcamModule
    ],
    declarations: [
        NewUpload,
        Download,
        DocumentComponent,
        Upload,
        PhotoCaptureComponent,
        CapturePopup
    ],
    entryComponents: [CapturePopup],
    exports: [
        MaterialModule,
        NewUpload,
        Download,
        Upload,
        DocumentComponent,
        PhotoCaptureComponent,
        PipeModule
    ],
    providers: [
        CookieService
    ]
})
export class DocumentControlsModule {
}

export * from "./controls/new-upload/sis-upload-2.component"
export * from "./controls/download/sis-download.component"
export * from "./controls/document/sis-document.component"
export * from "./controls/upload/sis-upload.component"
export * from "./controls/capture/sis-photo-capture.component"
