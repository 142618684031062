import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {ControlMetadata} from "@synisys/idm-dynamic-controls-metadata";
import {DocumentService} from "../../document.service";
import {Observable} from "rxjs/Observable";
import "rxjs/Rx";
import {HttpErrorResponse, HttpEventType} from "@angular/common/http";
import {MessageService} from "@synisys/idm-message-language-service-client-js";
import {DocumentInfo, FileType} from "../../model/index";
import {ClassifierService} from '@synisys/idm-classifier-service-client-js';
import {AuthenticationService} from "@synisys/idm-authentication-client-js";
import {PolicyOptionDto, PolicyService} from "@synisys/idm-policy-client-js";
import {DocumentAuthProviderService} from "../../document-auth-provider.service";
import {SubscriptionManager} from "../../shared/subscription-manager.decorator";

@Component({
    moduleId: module.id,
    selector: "sis-upload-2",
    styleUrls: ["sis-upload-2.component.css"],
    templateUrl: "sis-upload-2.component.html"
})
@ControlMetadata({
    groups: [
        {
            identity: "Controls",
            name: "Controls",
        },
    ],
    template: `
        <sis-upload-2 class="sis-upload-control"
                    [id]="%{id}"
                    [documentId]="%{field}"
                    [height]="%{height}"
                    [categoryId]="%{categoryId}"
                    [entityId]="%{entityId}"
                    [documentRootName]="%{documentRootName}"
                    [filePolicy]="%{filePolicy}"
                    [documentPreviewEnabled]="%{documentPreviewEnabled}"
                    [showUploadDate]="%{showUploadDate}"
                    [showDocumentSize]="%{showDocumentSize}"
                    [showUploadedBy]="%{showUploadedBy}"
                    (fileUploadAction)="%{fileUploadAction}"
                    (removeDocumentAction)="%{removeDocumentAction}">
        </sis-upload-2>`,
    iconInfo: "fa-caret-square-o-down",
    name: "NewUpload",
})
@SubscriptionManager()
export class NewUpload implements OnInit {
    private static MB_IN_BYTES: number = 1048576;
    private static KB_IN_BYTES: number = 1024;
    private static DOCUMENT_POLICY_KEY = "2c191221-9041-11e6-821f-0050568f767f";

    @Input() public documentId: number;
    @Input() public id: string;
    @Input() public height: number;
    @Input() public categoryId: string;
    @Input() public entityId: number;
    @Input() public documentRootName: string;
    @Input() public filePolicy: PolicyOptionDto;

    private _metaData: Map<string, any> = null;
    @Input('metaData') public set metaData (metaData: Map<string, any>) {
        this._metaData = metaData;
        this.metaDataUpdateAction.emit(this.metaData);
    }

    public get metaData (): Map<string, any> {
        return this._metaData;
    }

    @Input() public documentPreviewEnabled: boolean;
    @Input() public showUploadDate: boolean;
    @Input() public showDocumentSize: boolean;
    @Input() public showUploadedBy: boolean;

    @Output() fileUploadAction = new EventEmitter<number>();
    @Output() removeDocumentAction = new EventEmitter<number>();
    @Output() metaDataUpdateAction = new EventEmitter<Map<string, any>>();

    public progressPercent: number;

    private _documentPresent: boolean;
    private _isUploadInProgress: boolean = false;
    private _uploadedFile: File;
    private _documentInfo: DocumentInfo;
    private _validationMessage: string = "";
    private _fileUpload: HTMLInputElement;
    private _date: number;
    private _size: number;
    private _userName: string;
    private _imageUrl: string = "";
    private allowedSize: number;
    private allowedFileExtensions: Array<string>;
    private allowedUserExtensions: Array<string>;

    private $sm: SubscriptionManager;

    constructor(private documentService: DocumentService,
                private messageService: MessageService,
                private classifierService: ClassifierService,
                private authenticationService: AuthenticationService,
                private policyService: PolicyService,
                private documentAuthService: DocumentAuthProviderService) {
    }

    ngOnInit(): void {
        if (this.documentId) {
            this.$sm = this.documentService.getIsDocumentPresent(this.documentId).subscribe(isConfirmed => {
                if (isConfirmed) {
                    this.setupConfirmedDocument();
                }
                else {
                    this.setupTempDocument();
                }
            });
        }
        this.setupDocumentPolicy();
    }

    onFileChange(event: any) {
        if (event.target.files.length > 0) {
            this._uploadedFile = event.target.files[0];
            this._validationMessage = "";
            this.uploadFile();
        }
    }

    public onDrop(event: any) {
        event.preventDefault();

        if (event.dataTransfer.items) {
            this._uploadedFile = event.dataTransfer.items[0].getAsFile();
        } else {
            this._uploadedFile = event.dataTransfer.files[0];
        }

        this._validationMessage = "";
        this.uploadFile();
    }

    public onDragOver(event: any) {
        event.preventDefault();
    }

    public onDragLeave(event: any) {
        event.preventDefault();
    }

    public onClick() {
        this._fileUpload.click();
    }

    public cancelFile() {
        this.clearFields();
    }

    public downloadFile() {
        this.$sm = this.documentService.getIsDocumentPresent(this._documentInfo.id).subscribe(isConfirmed => {
            if(isConfirmed) {
                this.$sm = this.documentAuthService.getDocumentAuthInfo(this.categoryId, this.entityId, this.documentRootName)
                    .switchMap(authInfo => this.documentService.getDownloadUrl(this._documentInfo.id, authInfo))
                    .subscribe(url => {
                        this.documentService.downloadDocument(url, FileType.ORIGINAL);
                    });
            }
            else this.documentService.downloadTempDocument(this._documentInfo.id);
        });
    }

    private setupConfirmedDocument() {
        this.$sm = this.documentService.getDocumentInfo(this.documentId).switchMap((info: DocumentInfo) => {
            this._documentInfo = info;
            this._date = this._documentInfo.created * 1000;
            this._size = +this._documentInfo.size;
            this.setUserName(this._documentInfo.userId);

            return this.documentAuthService.getDocumentAuthInfo(this.categoryId, this.entityId, this.documentRootName);
        }).subscribe(authInfo => {
            if (this.isImage) {
                this.setImageUrl(authInfo);
            }
            this._documentPresent = true;
        })
    }

    private setupTempDocument() {
        this.$sm = this.documentService.getTempDocumentInfo(this.documentId).subscribe( (info: DocumentInfo) => {
            this._documentInfo = info;
            this._date = this._documentInfo.created * 1000;
            this._size = +this._documentInfo.size;
            this.setUserName(this._documentInfo.userId);

            if (this.isImage) {
                this.setImageUrl();
            }
            this._documentPresent = true;

        })
    }

    private setImageUrl(auth?: string) {
        const $url = !!auth ? this.documentService.getDocumentUrl(this._documentInfo.id, auth, FileType.THUMBNAIL) :
            this.documentService.getTempDocumentUrl(this._documentInfo.id, FileType.THUMBNAIL);

        this.$sm = $url.subscribe(imageUrl => {
            this._imageUrl = imageUrl
        });
    }

    private setupDocumentPolicy() {
        this.$sm = this.authenticationService.getUserData().flatMap(data => {
            const policyOptions$ = this.filePolicy ? this.policyService.getPolicyOptions(): Observable.of(null);

            return Observable.zip(this.policyService.getUserPolicy(data.userId, NewUpload.DOCUMENT_POLICY_KEY), policyOptions$);
        }).subscribe(([policy, policyOptions]: [PolicyOptionDto, Array<PolicyOptionDto>]) => {

            const policyOption = JSON.parse(policy.policyOptionParam);
            this.allowedSize = policyOption["maxSize"];
            this.allowedUserExtensions = this.getAllowedExtensions(policyOption);

            if (this.filePolicy) {
                const specifiedPolicy = policyOptions.find(policy => this.filePolicy.id === policy.id);
                this.allowedFileExtensions = this.getAllowedExtensions(JSON.parse(specifiedPolicy.policyOptionParam));
            }
        });
    }

    private uploadFile() {
        this.$sm = this.validate().subscribe(
            (next: any) => {
                this._isUploadInProgress = true;
                this.fileUpload(this._uploadedFile);
            },
            (error: any) => {
                this.clearFields();
                if (error instanceof HttpErrorResponse) {
                    if (error.status === 400) {
                        if (error.error.hasOwnProperty("type") && error.error.type === "EXTENSION") {
                            this.$sm = this.messageService
                                .getMessageWithPlaceholder("document.service.errors.extension", [
                                    error.error.currentExtension,
                                ])
                                .subscribe(message => (this.validationMessage = message));
                        } else if (error.error.hasOwnProperty("type") && error.error.type === "SIZE") {
                            this.$sm = this.messageService
                                .getMessageWithPlaceholder("document.service.errors.size", [error.error.currentSize])
                                .subscribe(message => (this.validationMessage = message));
                        }
                    }
                } else {
                    this.validationMessage = error;
                }
            }
        );
    }

    private validate(): Observable<any> {
        if (!this._uploadedFile) {
            return this.messageService.getMessage("document.service.errors.no_file")
                .map(err => Observable.throw(err));
        }

        if (this.allowedSize && this._uploadedFile.size > this.allowedSize * NewUpload.MB_IN_BYTES) {
            return this.messageService
                .getMessageWithPlaceholder(
                    "document.service.errors.size_validation", [this.allowedSize.toString()])
                .switchMap(err => Observable.throw(err));
        }

        if (this.filePolicy && this.isInvalidFileExtension(this.allowedFileExtensions)) {
            return this.messageService
                .getMessageWithPlaceholder(
                    "document.service.errors.extension_validation",
                    [this.allowedFileExtensions.map( policy => policy.toLowerCase()).join(", ")])
                .switchMap(err => Observable.throw(err));
        }

        if (this.isInvalidFileExtension(this.allowedUserExtensions)) {
            return this.messageService
                .getMessageWithPlaceholder(
                    "document.service.errors.extension_validation",
                    [this.allowedUserExtensions.map( policy => policy.toLowerCase()).join(", ")])
                .switchMap(err => Observable.throw(err));
        }

        return this.documentService.validate(this._uploadedFile);
    }

    private fileUpload(file: File) {
        this.$sm = this.documentAuthService.getDocumentAuthInfoForUpload(this.categoryId, this.entityId, this.documentRootName)
            .switchMap((authInfo: string) => {
                return this.documentService.uploadAuthWithProgress(this.categoryId, this._uploadedFile.name, file, authInfo, this._metaData);
            }).subscribe((event: any) => {
                if (event.type === HttpEventType.UploadProgress) {
                    this.progressPercent = Math.round(100 * event.loaded / event.total);
                }
                else if (event.type ==  HttpEventType.Response) {
                    this._isUploadInProgress = false;

                    this._documentInfo = <DocumentInfo> event.body;
                    this._date = (new Date()).valueOf();
                    this.setUserName(this._documentInfo.userId);

                    if (this.isImage) {
                        this.setImageUrl();
                    }
                    this._documentPresent = true;
                    this.fileUploadAction.emit(this._documentInfo.id);
                }
                },
            (error: HttpErrorResponse) => {
                if (error.status === 404) {
                    if (typeof error.error === 'string' && (<string>error.error).startsWith("Policy")) {
                        this._validationMessage = error.error;
                    }
                }
            });
    }

    private setUserName(userId: number): void {
        this.$sm =  this.classifierService.loadClassifier("User", userId, false)
            .flatMap(classifier => {
                if (classifier === null || classifier.getId() === null) {
                    return Observable.of(null);
                }
                return this.classifierService.getEntityName("User", classifier);
            }).subscribe(userName => {
                this._userName = userName;
        });
    }

    private getAllowedExtensions(policies: Object): Array<string> {
        let extensions: Array<string> = [];
        for (let policy in policies) {
            if (policy === "maxSize") continue;
            extensions = extensions.concat(policies[policy]);
        }
        return extensions;
    }

    private isInvalidFileExtension(extensions: Array<string>): boolean {
        return !extensions.some(extension =>
            this._uploadedFile.name.toLowerCase().endsWith(extension.toLowerCase().replace(' ', '')))
    }

    private clearFields() {
        if (this._documentInfo != null) {
            this.removeDocumentAction.emit(this._documentInfo.id);
        }
        this._documentPresent = false;
        this._uploadedFile = null;
        this._documentInfo = null;
        this._date = null;
        this._userName = null;
        this._imageUrl = "";
    }

    get documentPresent(): boolean {
        return this._documentPresent;
    }

    get isUploadInProgress(): boolean {
        return this._isUploadInProgress;
    }

    get sizeInKB(): number {
        return Math.round(+this._documentInfo.size / NewUpload.KB_IN_BYTES);
    }

    get isImage(): boolean {
        const imageTypes = ["bmp", "gif", "img", "jpe", "jpeg", "jpg", "mac", "pbm", "pct", "png", "ppm", "psd", "tiff"];
        return imageTypes.some(value => (this._documentInfo.name).toLowerCase().endsWith(value));
    }

    get uploadedFile(): File {
        return this._uploadedFile;
    }

    get validationMessage(): string {
        return this._validationMessage;
    }

    set validationMessage(value: string) {
        this._validationMessage = value;
    }

    get date(): number {
        return this._date;
    }

    get userName(): string {
        return this._userName;
    }

    get imageUrl(): any {
        return this._imageUrl;
    }

    get documentName(): string {
        return this._documentInfo.name;
    }

    public getMessage(key: string): any {
        return this.messageService.getMessage(key);
    }
}
