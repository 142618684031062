import {Component, Input, OnInit} from "@angular/core";
import {ControlMetadata} from "@synisys/idm-dynamic-controls-metadata";
import {DocumentService} from "../../document.service";
import {Observable} from "rxjs/Observable";
import "rxjs/Rx";
import {MessageService} from "@synisys/idm-message-language-service-client-js";
import {DocumentInfo, FileType} from "../../model/index";
import {ClassifierService} from '@synisys/idm-classifier-service-client-js';
import {DocumentAuthProviderService} from "../../document-auth-provider.service";
import {SubscriptionManager} from "../../shared/subscription-manager.decorator";

@Component({
    moduleId: module.id,
    selector: "sis-download",
    styleUrls: ["sis-download.component.css"],
    templateUrl: "sis-download.component.html"
})
@ControlMetadata({
    groups: [
        {
            identity: "Controls",
            name: "Controls",
        },
    ],
    template: `
        <sis-download class="sis-download-control"
                    [documentId]="%{documentId}"
                    [height]="%{height}"
                    [documentRootName]="%{documentRootName}"
                    [categorySystemName]="%{categorySystemName}"
                    [entityId]="%{entityId}"
                    [documentPreviewEnabled]="%{documentPreviewEnabled}"
                    [showUploadDate]="%{showUploadDate}"
                    [showDocumentSize]="%{showDocumentSize}"
                    [showUploadedBy]="%{showUploadedBy}">
        </sis-download>`,
    iconInfo: "fa-caret-square-o-down",
    name: "Download",
})
@SubscriptionManager()
export class Download implements OnInit {
    private static _KB_IN_BYTES: number = 1024;
    private static FILE_NOT_FOUND: string = "document.service.errors.file_not_found";

    @Input() public documentId: number;
    @Input() public height: number;
    @Input() public documentRootName: string;
    @Input() public categorySystemName: string;
    @Input() public entityId: number;

    @Input() public documentPreviewEnabled: boolean;
    @Input() public showUploadDate: boolean;
    @Input() public showDocumentSize: boolean;
    @Input() public showUploadedBy: boolean;

    public isReady: boolean = false;

    private _documentInfo: DocumentInfo;
    private _date: number;
    private _size: number = null;
    private _name: string = "";
    private _userName: Observable<string>;
    private _imageUrl: string = "";
    private _errorMessage: string = null;
    private authInfo: string;
    private isValidDocument = false;

    private $sm: SubscriptionManager;

    constructor(private documentService: DocumentService,
                private messageService: MessageService,
                private classifierService: ClassifierService,
                private documentAuthService: DocumentAuthProviderService) {
    }

    ngOnInit(): void {
        if (this.documentId) {

            this.$sm = this.documentService.getIsDocumentPresent(this.documentId).switchMap(isPresent => {
                this.isValidDocument = isPresent;

                if (!isPresent) {
                    this._errorMessage = Download.FILE_NOT_FOUND;
                    return Observable.of(null);
                }

                return Observable.zip(
                    this.documentService.getDocumentInfo(this.documentId),
                    this.documentAuthService.getDocumentAuthInfo(this.categorySystemName, this.entityId, this.documentRootName));
            }).subscribe( ([info, auth]: [DocumentInfo, string]) => {

                if (this.isValidDocument) {
                    this._documentInfo = info;
                    this._userName = this.getUserName(this._documentInfo.userId);
                    this._name = this._documentInfo.name;
                    this._date = this._documentInfo.created * 1000;
                    this._size = +this._documentInfo.size;

                    this.authInfo = auth;

                    if (this.isImage) {
                        this.setImageUrl();
                    }
                    this.isReady = true;

                }
            });
        }
    }

    public downloadFile() {
        this.$sm = this.documentService.getDownloadUrl(this.documentId, this.authInfo).subscribe((url: string) => {
            return this.documentService.downloadDocument(url, FileType.ORIGINAL);
        });
    }

    private getUserName(userId: number): Observable<string> {
        return this.classifierService.loadClassifier("User", userId, false)
            .flatMap(classifier => {
                if (classifier === null || classifier.getId() === null) {
                    return Observable.of(null);
                }
                return this.classifierService.getEntityName("User", classifier);
            });
    }

    private setImageUrl() {
        this.$sm = this.documentService.getDocumentUrl(this._documentInfo.id, this.authInfo,  FileType.THUMBNAIL).subscribe(url => {
            this._imageUrl = url;
        });
    }

    get sizeInKB(): number {
        return Math.round(this._size / Download._KB_IN_BYTES);
    }

    get isImage(): boolean {
        const imageTypes = ["bmp", "gif", "img", "jpe", "jpeg", "jpg", "mac", "pbm", "pct", "png", "ppm", "psd", "tiff"];
        return imageTypes.some(value => (this._name).toLowerCase().endsWith(value));
    }

    get date(): number {
        return this._date;
    }

    get size(): number {
        return this._size;
    }

    get userName(): Observable<string> {
        return this._userName;
    }

    get name(): string {
        return this._name;
    }

    get imageUrl(): any {
        return this._imageUrl;
    }

    get errorMessage(): string {
        return this._errorMessage;
    }

    public getMessage(key: string): any {
        return this.messageService.getMessage(key);
    }
}
