import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {MatDialog} from "@angular/material";
import {CapturePopup} from "./sis-photo-capture-popup.component";
import {WebcamImage, WebcamUtil} from "ngx-webcam";
import {DocumentService} from "../../document.service";
import {FileType} from "../../model/index";
import {ControlMetadata} from "@synisys/idm-dynamic-controls-metadata";
import {SubscriptionManager} from "../../shared/subscription-manager.decorator";

@Component({
    moduleId: module.id,
    selector: "sis-photo-capture",
    templateUrl: "sis-photo-capture.component.html",
    styleUrls: ["sis-photo-capture.component.css"]
})
@ControlMetadata({
    groups: [
        {
            identity: "Controls",
            name: "Controls",
        },
    ],
    template: `
		<sis-photo-capture
        [categoryId]="category"
        [documentId]="%{field}"
        (photoUpdate)="%{photoUpdate}"
        (photoRemove)="%{photoRemove}">
		</sis-photo-capture>`,
    name: "PhotoCaptureComponent",
    defaultActions:{
        'photoUpdate' : 'submitDocument',
        'photoRemove' : 'removeDocument'
    }
})
@SubscriptionManager()
export class PhotoCaptureComponent implements OnInit {

    private static readonly IMAGE_TYPE: string = "jpeg";

    private static nextPhotoId: number = 0;

    public readonly hasCamera: Promise<boolean>;

    private $sm: SubscriptionManager;

    @Input()
    public imageUrl: string;

    @Input()
    private categoryId: string;

    @Input()
    private documentId: number;

    private height = 240;

    @Output()
    private photoUpdate = new EventEmitter<number>();

    @Output()
    private photoRemove = new EventEmitter<number>();

    @Input()
    public metaData: Map<string, any> = null;

    constructor(private dialog: MatDialog,
                private documentService: DocumentService) {
        this.hasCamera = this.hasAnyCamera();
    }

    ngOnInit(): void {
        if (this.documentId) {
            this.$sm = this.documentService.getTempDocumentUrl(this.documentId, FileType.ORIGINAL)
                .subscribe(url => this.imageUrl = url);
        }
    }

    openCapturePopup() {
        const capturePopup = this.dialog.open(CapturePopup, {data: {imageType: PhotoCaptureComponent.IMAGE_TYPE}});
        capturePopup.afterClosed().subscribe((image: WebcamImage) => {
            if (image) {
                this.imageUrl = image.imageAsDataUrl;
                const file = PhotoCaptureComponent.convertImageToFile(image);
                this.$sm = this.documentService.validate(file).switchMap(() => {
                    return this.documentService.upload(this.categoryId, file.name, file, this.metaData);
                }).subscribe(document => {
                    this.photoUpdate.emit(document.id);
                });
            }
        });
    }

    deletePhoto() {
        this.imageUrl = null;
        this.photoRemove.emit(this.documentId);
    }

    private static convertImageToFile(image: WebcamImage): File {
        const decoded: string = atob(image.imageAsBase64);
        let length: number = decoded.length;

        const arr = new Uint8Array(length);
        for (let i = 0; i < length; i++) {
            arr[i] = decoded.charCodeAt(i);
        }

        return new File([arr], `photo${this.nextPhotoId++}.${this.IMAGE_TYPE}`, {type: `image/${this.IMAGE_TYPE}`});
    }

    private hasAnyCamera(): Promise<boolean> {
        return WebcamUtil.getAvailableVideoInputs().then(value => {
            return value.length !== 0;
        });
    }
}
