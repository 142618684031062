import {Pipe, PipeTransform} from '@angular/core';
import {LocaleService} from "@synisys/idm-locale-service-client-js";
import {CurrentLanguageProvider} from "@synisys/idm-session-data-provider-api-js";
import {Observable} from "rxjs/Observable";
import {Language} from "@synisys/idm-crosscutting-concepts-frontend";

interface IDateFormat {
    date: string,
    dateTime: string,
    time: string
}

@Pipe({
    name: 'sysLocalDate'
})

export class LocalDatePipe implements PipeTransform {
    private format: IDateFormat = {
        date: 'date',
        dateTime: 'dateTime',
        time: 'time'
    };

    constructor(private httpLocaleService: LocaleService,
                private _currentLanguageProvider: CurrentLanguageProvider) {
    }

    transform(date: number, format: string = this.format.date): Observable<string> {
        const currentLanguageObservable: Observable<Language> = this._currentLanguageProvider.getCurrentLanguage();
        if (date == null || date == undefined || typeof date !== "number") {
            return null;
        }
        switch (format) {
            case this.format.date:
                return currentLanguageObservable.mergeMap(lang => Observable.from(this.httpLocaleService.formatDate(date, lang.getId())));
            case this.format.dateTime:
                return currentLanguageObservable.mergeMap(lang => Observable.from(this.httpLocaleService.formatDateTime(date, lang.getId())));
            case this.format.time:
                return currentLanguageObservable.mergeMap(lang => Observable.from(this.httpLocaleService.formatTime(date, lang.getId())));
        }
    }
}
