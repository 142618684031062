import {Component, Inject} from "@angular/core";
import {WebcamImage, WebcamInitError} from "ngx-webcam";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";

@Component({
    moduleId: module.id,
    templateUrl: "sis-photo-capture-popup.component.html",
    styleUrls: ["sis-photo-capture-popup.component.css"]
})
export class CapturePopup {

    private readonly imageType: string;

    private image: WebcamImage;

    public isCaptureMode = true;

    public imageUrl: string;

    public errorMessage: string;

    constructor(@Inject(MAT_DIALOG_DATA) private data: any, private popup: MatDialogRef<CapturePopup>) {
        this.imageType = `image/${data.imageType}`;
    }

    capture(image: WebcamImage) {
        this.image = image;
        this.isCaptureMode = false;
        this.imageUrl = image.imageAsDataUrl;
    }

    handleError(initError: WebcamInitError) {
        this.errorMessage = initError.message;
        console.error(initError);
    }
}
