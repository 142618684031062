import {Injectable} from '@angular/core';
import {DocumentService} from '../document.service';
import {PreconditionCheck, StringTemplate} from '@synisys/idm-common-util-frontend';
import {ApplicationPropertiesService} from '@synisys/idm-application-properties-service-client-js';
import {DocumentInfo, FileType} from '../model';
import {AuthenticationService, CookieService, HttpClientWrapper, UserData} from '@synisys/idm-authentication-client-js';

import {Observable} from 'rxjs/Observable';
import 'rxjs/add/observable/from';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/filter';
import 'rxjs/add/observable/zip';
import {HttpErrorResponse, HttpParams} from "@angular/common/http";
import {HttpClient} from "@angular/common/http";

@Injectable()
export class HttpDocumentService extends DocumentService {
    public SERVER_TIMESTAMP_URL: string = 'time';
    private DOCUMENT_SERVICE_URI_KEY: string = 'document-service-url';

    public URL_DOCUMENT: StringTemplate = StringTemplate.createTemplate`${'serviceUri'}/document/`;
    public UPLOAD_DOCUMENT_AUTH: StringTemplate = StringTemplate.createTemplate`${'serviceUri'}/document/auth`;
    public URL_DOCUMENT_UPDATE: StringTemplate = StringTemplate.createTemplate`${'serviceUri'}/document/${'documentId'}`;
    public URL_DOCUMENT_INFO: StringTemplate = StringTemplate.createTemplate`${'serviceUri'}/document/info/${'documentId'}`;
    public URL_TEMP_DOCUMENT_INFO: StringTemplate = StringTemplate.createTemplate`${'serviceUri'}/document/info/temp/${'documentId'}`;
    public URL_DOCUMENT_PRESENT: StringTemplate = StringTemplate.createTemplate`${'serviceUri'}/document/present/${'documentId'}`;
    public URL_VALIDATE: StringTemplate = StringTemplate.createTemplate`${'serviceUri'}/validate/document`;
    public URL_CONFIRM_UPLOAD: StringTemplate = StringTemplate.createTemplate`${'serviceUri'}/document/${'documentId'}`;
    public URL_GET_DOWNLOAD_LINK: StringTemplate = StringTemplate.createTemplate`${'serviceUri'}/document/tempUrl/${'documentId'}`;
    public URL_GET_MULTIPLE_DOWNLOAD_LINK: StringTemplate = StringTemplate.createTemplate`${'serviceUri'}/document/tempUrl/batch`;

    public URL_DOWNLOAD: StringTemplate = StringTemplate.createTemplate`${'serviceUri'}/document/${'tempUrl'}`;
    public URL_TEMP_DOWNLOAD: StringTemplate = StringTemplate.createTemplate`${'serviceUri'}/document/temp/${'id'}`;

    public URL_DOCUMENT_DELETE: StringTemplate = StringTemplate.createTemplate`${'serviceUri'}/document/${'documentId'}`;


    public constructor(
        private http: HttpClientWrapper,
        private httpPure: HttpClient,
        private applicationPropertiesService: ApplicationPropertiesService,
        private cookieService: CookieService,
        private authenticationService: AuthenticationService) {
        super();
    }


    public upload(categoryId: string, title: string, file: File, metaData: Map<string, any> = null): Observable<DocumentInfo> {
        return Observable.from(this.applicationPropertiesService.getProperty(this.DOCUMENT_SERVICE_URI_KEY))
            .mergeMap((serviceUri: string) => {
                let url = this.URL_DOCUMENT.replaceTemplate({
                    'serviceUri': serviceUri
                });

                let formData = new FormData();

                formData.append("categoryId", categoryId);
                formData.append("title", title);
                formData.append("file", file, file.name);

                metaData && metaData.forEach((value: any, key: string) => {
                    if (!formData.has(key)) {
                        formData.append(key, value);
                    }
                });

                return this.http.post(url, formData)
                    .map((data) => {
                        return <DocumentInfo>data;
                    });
            }).catch(this.handleError);
    }


    public uploadWithAuth(categoryId: string, title: string, file: File, authInfo: string, metaData: Map<string, any> = null): Observable<DocumentInfo> {
        return this.uploadAuth(categoryId, title, file, authInfo, false, metaData)
            .map((data) => {
                return <DocumentInfo>data;
            });
    }


    public uploadAuthWithProgress(categoryId: string, title: string, file: File, authInfo: string, metaData: Map<string, any> = null): Observable<any> {
        return this.uploadAuth(categoryId, title, file, authInfo, true, metaData);
    }


    private uploadAuth(categoryId: string, title: string, file: File, authInfo: string, reportProgress: boolean = false, metaData: Map<string, any> = null): Observable<any> {
        return Observable.from(this.applicationPropertiesService.getProperty(this.DOCUMENT_SERVICE_URI_KEY))
            .mergeMap((serviceUrl: string) => {
                let url: string = this.UPLOAD_DOCUMENT_AUTH.replaceTemplate({
                    'serviceUri': serviceUrl
                });

                url += `?authInfo=${authInfo}`;

                let formData = new FormData();

                formData.append("categoryId", categoryId);
                formData.append("title", title);
                formData.append("file", file, file.name);

                metaData && metaData.forEach((value: any, key: string) => {
                    if (!formData.has(key)) {
                        formData.append(key, value);
                    }
                });

                const observe = reportProgress ? 'events' : null;

                return this.http.post(url, formData, {reportProgress: reportProgress, observe: observe});
            }).catch(this.handleError);
    }


    public validate(file: File): Observable<any> {
        return Observable.from(this.applicationPropertiesService.getProperty(this.DOCUMENT_SERVICE_URI_KEY))
            .mergeMap((serviceUri: string) => {
                let url: string = this.URL_VALIDATE.replaceTemplate({
                    'serviceUri': serviceUri
                });

                let formData = new FormData();
                formData.append("file", file, file.name);

                return this.http.post(url, formData);
            }).catch(error => Observable.throw(error));

    }


    public confirmUpload(documentId: number, entityId: number): Observable<DocumentInfo> {
        return Observable.from(this.applicationPropertiesService.getProperty(this.DOCUMENT_SERVICE_URI_KEY))
            .mergeMap((serviceUri: string) => {
                let url: string = this.URL_CONFIRM_UPLOAD.replaceTemplate({
                    'serviceUri': serviceUri,
                    'documentId': documentId.toString()
                });

                return this.http.put(url, { 'entityId': entityId }).map((data) => {
                    return <DocumentInfo>data;
                });
            }).catch(this.handleError);
    }


    public getDownloadUrl(documentId: number, authInfo: string): Observable<string> {
        return Observable.from(this.applicationPropertiesService.getProperty(this.DOCUMENT_SERVICE_URI_KEY))
            .mergeMap((serviceUri: string) => {
                let url: string = this.URL_GET_DOWNLOAD_LINK.replaceTemplate({
                    'serviceUri': serviceUri,
                    'documentId': documentId.toString()
                });

                url += `?authInfo=${authInfo}`;

                return this.http.get(url).map((data) => {
                    return data['tempUrl'];
                });
            }).catch(this.handleError);
    }


    public getDownloadUrls(documentIds: Array<number>, authInfo: string): Observable<Map<number, string>> {
        return Observable.from(this.applicationPropertiesService.getProperty(this.DOCUMENT_SERVICE_URI_KEY))
            .mergeMap((serviceUri: string) => {
                let url: string = this.URL_GET_MULTIPLE_DOWNLOAD_LINK.replaceTemplate({
                    'serviceUri': serviceUri
                });

                url += `?documentIds=${documentIds.join(',')}`;
                url += `&authInfo=${authInfo}`;

                return this.http.get(url).map((data) => {
                    let urls: Map<number, string> = new Map<number, string>();
                    for (let key in data) {
                        if (data.hasOwnProperty(key)) {
                            urls.set(Number(key), data[key])
                        }
                    }
                    return urls;
                });
            }).catch(this.handleError);
    }


    public downloadDocument(tempUrl: string, fileType: FileType): void {
        Observable.zip(
            Observable.from(this.applicationPropertiesService.getProperty(this.DOCUMENT_SERVICE_URI_KEY)),
            this.authenticationService.getUserData(),
            this.httpPure.get(this.SERVER_TIMESTAMP_URL)
                .map((data) => {
                    if(typeof data === 'object' && Object.getOwnPropertyNames(data).indexOf('time') != -1) {
                        return data['time'];
                    }
                    return 0;
                })
                .catch(() => Observable.of(0))
        ).subscribe(([serviceUri, currentUserData, serverTime]: [string, UserData, number]) => {

            let url: string = this.URL_DOWNLOAD.replaceTemplate({
                'serviceUri': serviceUri,
                'tempUrl': tempUrl
            });

            // add userID parameter
            url = `${url}?userID=${currentUserData.userId}`;

            // add time parameter if exist
            if(serverTime) {
                url = `${url}&time=${serverTime}`;
            }

            // add fileType parameter if exist
            if (fileType) {
                url = `${url}&fileType=${fileType}`;
            }

            // add access_token parameter
            url = `${url}&access_token=${this.cookieService.get('access_token')}`;
            window.open(url);
        });
    }


    public downloadTempDocument(id: number): void {
        Observable.from(this.applicationPropertiesService.getProperty(this.DOCUMENT_SERVICE_URI_KEY))
            .subscribe((serviceUri: string) => {
                let url: string = this.URL_TEMP_DOWNLOAD.replaceTemplate({
                    'serviceUri': serviceUri,
                    'id': id.toString()
                });

                window.open(`${url}?access_token=${this.cookieService.get('access_token')}`);
            });
    }


    public getDocumentUrl(documentId: number, authInfo: string, fileType: FileType): Observable<string> {
        PreconditionCheck.notNullOrUndefined(documentId);
        PreconditionCheck.notNullOrUndefined(authInfo);
        PreconditionCheck.notNullOrUndefined(fileType);
        return Observable.zip(
            this.getDownloadUrl(documentId, authInfo),
            Observable.from(this.applicationPropertiesService.getProperty(this.DOCUMENT_SERVICE_URI_KEY))
        ).map(([tempUrl, serviceUri]: [string, string]) => {
            let url: string = this.URL_DOWNLOAD.replaceTemplate({
                'serviceUri': serviceUri,
                'tempUrl': tempUrl
            });

            return `${url}?fileType=${fileType}&access_token=${this.cookieService.get('access_token')}`;
        }).catch(this.handleError);
    }


    public getTempDocumentUrl(documentId: number, fileType?: FileType): Observable<string> {
        return Observable.from(this.applicationPropertiesService.getProperty(this.DOCUMENT_SERVICE_URI_KEY))
            .map((serviceUri: string) => {
                let url: string = this.URL_TEMP_DOWNLOAD.replaceTemplate({
                    'serviceUri': serviceUri,
                    'id': documentId.toString()
                });
                if (fileType) {
                    return `${url}?fileType=${fileType}&access_token=${this.cookieService.get('access_token')}`;
                }
                return `${url}?access_token=${this.cookieService.get('access_token')}`;
            }).catch(this.handleError);
    }


    public getTempDocumentInfo(documentId: number): Observable<DocumentInfo> {
        return Observable.from(this.applicationPropertiesService.getProperty(this.DOCUMENT_SERVICE_URI_KEY))
            .mergeMap((serviceUri: string) => {
                let url: string = this.URL_TEMP_DOCUMENT_INFO.replaceTemplate({
                    'serviceUri': serviceUri,
                    'documentId': documentId.toString()
                });
                return this.http.get(url)
            }).map((data) => {
                return <DocumentInfo>data;
            }).catch(this.handleError);
    }


    public getDocumentInfo(documentId: number): Observable<DocumentInfo> {
        return Observable.from(this.applicationPropertiesService.getProperty(this.DOCUMENT_SERVICE_URI_KEY))
            .mergeMap((serviceUri: string) => {
                let url: string = this.URL_DOCUMENT_INFO.replaceTemplate({
                    'serviceUri': serviceUri,
                    'documentId': documentId.toString()
                });
                return this.http.get(url)
            }).map((data) => {
                return <DocumentInfo>data;
            }).catch(this.handleError);
    }


    public getDocumentsByEntityId(categoryId: string, entityId: number): Observable<DocumentInfo[]> {
        return Observable.from(this.applicationPropertiesService.getProperty(this.DOCUMENT_SERVICE_URI_KEY))
            .flatMap((serviceUri: string) => {
                let url: string = this.URL_DOCUMENT.replaceTemplate({
                    'serviceUri': serviceUri
                });

                const params = new HttpParams()
                    .set('categoryId', categoryId)
                    .set('entityId', entityId.toString());

                return this.http.get(url, { params: params });
            })
            .map((data) => {
                return <DocumentInfo>data;
            })
            .catch(this.handleError);
    }


    public deleteDocumentById(documentId: number): Observable<boolean> {
        return Observable.from(this.applicationPropertiesService.getProperty(this.DOCUMENT_SERVICE_URI_KEY))
            .flatMap((serviceUri: string) => {
                let url: string = this.URL_DOCUMENT_DELETE.replaceTemplate({
                    'serviceUri': serviceUri,
                    'documentId': documentId.toString()
                });

                return this.http.delete(url);
            })
            .map(_ => {
                return true;
            })
            .catch(this.handleError);
    }


    public getIsDocumentPresent(documentId: number): Observable<boolean> {
        return Observable.from(this.applicationPropertiesService.getProperty(this.DOCUMENT_SERVICE_URI_KEY))
            .flatMap((serviceUri: string) => {
                let url: string = this.URL_DOCUMENT_PRESENT.replaceTemplate({
                    'serviceUri': serviceUri,
                    'documentId': documentId.toString()
                });

                return this.http.get(url);
            })
            .catch(this.handleError);
    }


    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            console.error('An error occurred:', error.error.message);
        } else {
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);
        }
        return Observable.throw(error);
    }
}
